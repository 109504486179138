.rippled {
    position: relative;
    z-index: 0;
}
.rippled::before {
    background: transparent no-repeat 0 0;
    background-image: url(../../images/ripple.svg);
    background-size: cover;
    content: '';
    display: block;
    height: 58rem;
    pointer-events: none;
    position: absolute;
    top: 50%; right: auto; bottom: auto; left: 50%;
    transform: translate(-50%, -50%);
    width: 58rem;
    z-index: 1;
}