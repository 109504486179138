.background {
    background: var(--secondary);
    color: #fff;
    position: relative;

    ion-content {
        --background: transparent;
        --color: #ffffff;
    }
}
.background::before {
    background: transparent no-repeat 50% 50%;
    background-image: url(../../images/bg.png);
    background-size: cover;
    content: '';
    opacity: 0.2;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
}