html,
body {
    font-size: 10px;
    overflow: hidden;
    overscroll-behavior: none;
}
body {
    font-size: 1.4rem;

    .modal-lg {
        max-width: 1300px;
    }
}

.container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: 100%;
}

ion-content {
    --background: #f2f2f2;
    --color: #444;
}
ion-item {
    --padding-start: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
}

a {
    text-decoration: none;
    &:active,
    &:focus,
    &:hover {
        text-decoration: none !important;
    }
}
