select.form-control {
    appearance: auto;
}

.form-control {
    background-clip: inherit;
    border: 1px solid #ddd;
    border-radius: 0;
    color: #444;
    font-size: 1.6rem;
    font-family: $font-family-sans-serif;
    font-weight: 400;
    min-height: 4.4rem;
    height: auto;
    padding: 1rem;
    text-align: left;

    &:focus {
        border-color: $primary;
        box-shadow: none;
    }
    &:focus ~ .form-control-label {
        color: $primary;
    }

    select {
        appearance: auto;
    }

    &[readonly],
    &[disabled] {
        opacity: 0.3;
        pointer-events: none;
    }

    &[aria-describedby*='error'] {
        border-color: var(--ion-color-danger-tint);
        & ~ .form-control-label {
            color: var(--ion-color-danger);
        }
    }
    &[aria-describedby*='warning'] {
        border-color: var(--ion-color-warning-tint);
        & ~ .form-control-label {
            color: #856b00;
        }
    }
}
