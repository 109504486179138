.btn {
    align-items: center;
    border: 0;
    border-radius: 0;
    display: flex;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.8rem;
    justify-content: space-between;
    margin: 2rem 0 0;
    min-height: 4.4rem;
    padding: 1rem 1rem 0.8rem;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    -webkit-appearance: none !important;

    .icon {
        font-size: 1em;
        line-height: 1;
        &.fa-long-arrow-left,
        &.fa-long-arrow-right {
            font-size: 1.25em;
        }
    }
    .text {
        display: inline-block;
        margin: 0 auto 0 0;
        // transform: translateY(1px);
    }

    .icon + .text,
    .text + .icon {
        margin-left: 1rem;
    }
}
.btn.btn-sm {
    font-size: 1.2rem;
}

.btn.btn-dark {
    background: #999;
    &:active,
    &:focus,
    &:hover {
        background: #666;
    }
}

// Linkd buttons
// ----------------------------------------------

.btn-linkd {
    border-bottom: 2px solid var(--primary);
    color: var(--primary);
    display: inline-flex;
    min-height: 0;
    padding: 0 0 0.75rem 0;
    text-decoration: none;
    text-transform: uppercase;
    width: auto;

    .text {
        transform: translateY(0.1rem);
    }

    &:active,
    &:focus,
    &:hover {
        border-color: var(--primary-darken);
        color: var(--primary-darken);
    }

    &.btn-block {
        display: flex;
    }
}

// Round buttons
// ----------------------------------------------

.btn-round {
    border-radius: 3.5rem;
    display: inline-flex !important;
    font-size: 1.4rem;
    line-height: 2.5rem;
    min-height: auto;
    padding: 1rem 2rem 0.8rem;
    justify-content: center;
    width: auto !important;

    .text {
        transform: translateY(0);
    }
}
.btn-round.btn-sm {
    font-size: 1.2rem;
    line-height: 1;
    padding: 1rem 1.5rem;
}
