/* Ionic Variables and Theming. For more information, please see
// https://beta.ionicframework.com/docs/theming/
// The app direction is used to include
// rtl styles in your app. For more information, please see
// https://beta.ionicframework.com/docs/layout/rtl
// $app-direction: ltr;
// Ionic Colors
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic provides eight layered colors
// that can be changed to theme an app. Additional colors can be
// added as well (see below). For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
// To easily create custom color palettes for your app’s UI,
// check out our color generator:
// https://beta.ionicframework.com/docs/theming/color-generator
*/

:root {
    --os-angels: #5baa54;
    --os-angels-bgc: #eff7ee;
    --os-angels-contrast: #ffffff;
    --os-angels-shade: #40773b;
    --os-angels-tint: #8cc487;

    --os-corps: #000;
    --os-corps-bgc: #e6e6e6;
    --os-corps-contrast: #ffffff;
    --os-corps-shade: #000;
    --os-corps-tint: #4d4d4d;

    --os-evaluators: #e0b913;
    --os-evaluators-bgc: #fcf8e7;
    --os-evaluators-contrast: #ffffff;
    --os-evaluators-shade: #b3940f;
    --os-evaluators-tint: #e6c742;

    --os-startups: #45aaf2;
    --os-startups-bgc: #ecf7fe;
    --os-startups-contrast: #ffffff;
    --os-startups-shade: #3788c2;
    --os-startups-tint: #6abbf5;

    --os-tech: #ec7f22;
    --os-tech-bgc: #fdf2e9;
    --os-tech-contrast: #ffffff;
    --os-tech-shade: #bd661b;
    --os-tech-tint: #f0994e;

    --primary: #45aaf2;
    --primary-darken: #3788c2;
    --secondary: #004680;

    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    --ion-color-secondary: #0cd1e8;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-rgb: 12, 209, 232;
    --ion-color-secondary-shade: #0bb8cc;
    --ion-color-secondary-tint: #24d6ea;

    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;

    --ion-color-danger: #f04141;
    --ion-color-danger-bgc: #feecec;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-tint: #f25454;

    --ion-color-dark: #222428;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-info: #17a2b8;
    --ion-color-info-bgc: #e8f6f8;
    --ion-color-info-contrast: #ffffff;
    --ion-color-info-contrast-rgb: 255, 255, 255;
    --ion-color-info-shade: #128293;
    --ion-color-info-tint: #45b5c6;

    --ion-color-light: #f4f5f8;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-success: #28a745;
    --ion-color-success-bgc: #d4edda;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-shade: #155724;
    --ion-color-success-tint: #28e070;

    --ion-color-warning: #ffce00;
    --ion-color-warning-bgc: #fffae6;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;
}

/* Additional Ionic Colors
  // --------------------------------------------------
  // In order to add colors to be used with Ionic components,
  // the color should be added as a class with the convention `.ion-color-{COLOR}`
  // where `{COLOR}` is the color to be used on the Ionic component
  // and each variant is defined for the color. For more information, please see
  // https://beta.ionicframework.com/docs/theming/advanced
  */

.ion-color-favorite {
    --ion-color-base: #69bb7b;
    --ion-color-base-rgb: 105, 187, 123;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #5ca56c;
    --ion-color-tint: #78c288;
}

.ion-color-twitter {
    --ion-color-base: #1da1f4;
    --ion-color-base-rgb: 29, 161, 244;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #1a8ed7;
    --ion-color-tint: #34aaf5;
}

.ion-color-google {
    --ion-color-base: #dc4a38;
    --ion-color-base-rgb: 220, 74, 56;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #c24131;
    --ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
    --ion-color-base: #23b6ea;
    --ion-color-base-rgb: 35, 182, 234;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #1fa0ce;
    --ion-color-tint: #39bdec;
}

.ion-color-facebook {
    --ion-color-base: #3b5998;
    --ion-color-base-rgb: 59, 89, 152;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #344e86;
    --ion-color-tint: #4f6aa2;
}

/* Shared Variables
  // --------------------------------------------------
  // To customize the look and feel of this app, you can override
  // the CSS variables found in Ionic's source files.
  // To view all the possible Ionic variables, see:
  // https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
  */

:root {
    --ion-headings-font-weight: 300;
    /* Set the font family of the entire app */
    --ion-font-family: 'Montserrat', 'Helvetica Neue', 'Roboto', sans-serif;
}

.md {
    --ion-toolbar-background: var(--ion-color-primary);
    --ion-toolbar-color: #fff;
    --ion-toolbar-color-activated: #fff;
}
html.md {
    /* Set the font family of the entire app */
    --ion-default-family: var(--ion-font-family);
}

// BOOTSTRAP VARIABLES

$primary: #45aaf2;

$danger: #dc3545;
$info: #17a2b8;
$success: #28a745;
$warning: #ffc107;

$font-size-base: 0.875rem;
$font-family-sans-serif: 'Montserrat', 'Helvetica Neue', 'Roboto', sans-serif;
