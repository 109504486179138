.badge-success.badge {
    color: #fff;
    background-color: #28a745;
}

.badge-danger.badge {
    color: #fff;
    background-color: #dc3545;
}

.badge-secondary.badge {
    color: #fff;
    background-color: #6c757d;
}

.badge-warning.badge {
    color: #212529;
    background-color: #ffc107;
}

.badge {
    border-radius: 0;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1;
    padding: 0.3rem;
}
